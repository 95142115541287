<template>
  <div>
    <transition-group name="fade" tag="div">
      <Withdraw
        v-for="withdraw in withdraws"
        :key="withdraw.id"
        :withdraw="withdraw"
      />
    </transition-group>
  </div>
</template>

<script>
import Withdraw from "@/components/Profile/Payments/Withdraw";

export default {
  components: {
    Withdraw,
  },
  data() {
    return {
      withdraws: [],
    };
  },
  async created() {
    try {
      let { data } = await this.$http.get("wallet/withdraw/history");
      this.withdraws = data.response;
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },
};
</script>
