<template>
  <div>
    <div class="withdraw">
      <div class="item" @click="isShow = !isShow">
        <div v-if="withdraw.method" class="icon">
          <img
            v-if="withdraw.method === 'qiwi'"
            :alt="withdraw.method"
            src="@/assets/icons/profile/qiwi.png"
          />
          <img
            v-else-if="withdraw.method === 'piastrix'"
            :alt="withdraw.method"
            src="@/assets/icons/profile/piastrix.png"
          />
          <img
            v-else-if="withdraw.method === 'freekassa'"
            :alt="withdraw.method"
            src="@/assets/icons/profile/fkwallet.png"
          />
        </div>
        <div class="info">
          <div class="title">{{ withdraw.method }}</div>
          <div class="desc second">#{{ withdraw.id }}</div>
        </div>
      </div>
      <div class="item second">
        {{ withdraw.created_at | formatDate("yyyy-MM-dd") }}
      </div>
      <div class="item amount">
        {{ convertAmount(withdraw.amount, withdraw.currency) }}
        {{ icons[withdraw.currency] }}
      </div>
      <div class="item status last">
        <button
          v-if="withdraw.status == 0"
          class="danger cancel"
          @click="cancel"
        >
          Отменить
        </button>
        <button
          v-if="withdraw.status == 2 || withdraw.status == 4"
          class="danger"
        >
          Отклонено
        </button>
        <button v-else-if="withdraw.status == 0" class="waiting">
          В ожидании
        </button>
        <button v-else-if="withdraw.status == 5" class="waiting">
          В обработке
        </button>
        <button v-else-if="withdraw.status == 1" class="success">
          Успешно
        </button>
        <button v-else class="danger">Отменено пользователем</button>
      </div>
    </div>
    <div v-if="isShow" class="description">
      <p>
        Сумма транзакции:
        <span>
          {{ convertAmount(withdraw.total, withdraw.currency) }}
          {{ icons[withdraw.currency] }}
        </span>
      </p>
      <p>
        К получению:
        <span>
          {{ convertAmount(withdraw.amount, withdraw.currency) }}
          {{ icons[withdraw.currency] }}
        </span>
      </p>
      <p>
        Дата операции:
        <span>{{
          withdraw.created_at | formatDate("yyyy-MM-dd HH:mm:ss")
        }}</span>
      </p>
      <p>
        На кошелек: <span>{{ withdraw.purse }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    status: String,
    withdraw: Object,
  },
  computed: {
    ...mapState("user", ["icons"]),
    ...mapGetters("user", ["convertAmount"]),
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    async cancel() {
      try {
        const { data } = await this.$http.post("wallet/withdraw/cancel", {
          id: this.withdraw.id,
        });

        if (data.error) return this.$toast.error(data.error);
        this.withdraw.status = 3;

        this.$store.commit("user/balance", data.response.balances);
        this.$toast.success("Вы успешно создали заявку на вывод");
      } catch (error) {
        console.log(error?.message || "Ошибка отправки запроса");
      }
    },
  },
  filters: {
    formatDate(value, type) {
      return format(new Date(value.replace(" ", "T")), type);
    },
  },
};
</script>

<style lang="scss" scoped>
.withdraw {
  border-top: 1px solid #f5f6fa;
  display: flex;
  cursor: pointer;
  align-items: center;
  // &:hover {
  //   opacity: 0.8;
  // }
  .item {
    width: calc(100% / 5);
    &.last {
      width: calc(100% / 5 * 2);
      button:last-child {
        margin-left: 15px;
      }
    }
    display: flex;
    align-items: center;
    padding: 15px 0;
    &:last-child {
      justify-content: flex-end;
    }
    button {
      border-radius: 6px;
      display: block;
      color: white;
      padding: 15px 25px;
      //   font-weight: bold;
      font-size: 15px;
      &.success {
        background: #57c400;
      }
      &.danger {
        background: #ef4141;
      }
      &.waiting {
        background: #f4ca32;
      }
      &.cancel:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .icon {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      background: #f4f6f9;
      margin-right: 15px;
      border-radius: 8px;
      justify-content: center;
      img {
        width: 25px;
      }
    }
    .info {
      .title {
        // font-weight: 500;
        font-size: 20px;
        text-transform: capitalize;
        margin-bottom: 3px;
      }
    }
    &.amount {
      font-weight: 500;
      font-size: 17px;
      color: black;
    }
    .second,
    &.second {
      //   font-weight: 500;
      font-size: 15px;
      color: #a3afc9;
    }
  }
}
.description {
  p {
    margin-bottom: 15px;
    font-size: 16px;
    color: #a3afc9;
    span {
      color: black;
    }
  }
}
</style>
